<template>
  <a-card :bordered="false">
    <a-form :model="where">
      <a-row>
        <a-space>
          <a-form-item label="字段名称:">
            <a-input
              v-model:value.trim="where.label"
              placeholder="请输入字段名称"
              allow-clear
            />
          </a-form-item>
          <!-- <a-form-item label="字段选项:">
            <a-select
              v-model:value="where.fieldType"
              placeholder="请选择字段"
              allow-clear
              :dropdownMatchSelectWidth="true"
            >
              <a-select-option :value="null"> 请选择 </a-select-option>
              <a-select-option :value="1"> 常规字段 </a-select-option>
              <a-select-option :value="2"> 自定义字段 </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="是否启用:">
            <a-switch
              :checked="where.enable"
              @change="(checked) => (where.enable = checked)"
            />
          </a-form-item> -->
          <a-form-item label="是否必填:">
            <a-switch
              :checked="where.verify"
              @change="(checked) => (where.verify = checked)"
            />
          </a-form-item>
          <a-form-item class="ud-span-right" :wrapper-col="{ span: 24 }">
            <a-space>
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-item>
        </a-space>
      </a-row>
    </a-form>
    <div>
      <div>
        <a-row :gutter="6">
          <a-col :span="12">
            <a-row :gutter="6">
              <a-col
                :span="4"
                style="margin-bottom: 10px"
                v-for="(item, index) in fieldList"
                :key="index"
              >
                <div
                  class="field-item"
                  @click="enableField(item)"
                  :class="item.isSelect ? 'field-item-act' : 'field-item-def'"
                >
                  <span
                    ><span
                      v-if="item.verify"
                      style="color: red; margin-right: 2px"
                      >*</span
                    >{{ item.label }}</span
                  >
                  <div class="xsj" v-if="item.isSelect"></div>
                  <div class="icon" v-if="item.isSelect">
                    <check-outlined
                      style="color: #fff; font-weight: bolder; font-size: 16px"
                    />
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="12" v-if="selection.length > 0">
            <div class="selection-wrap">
              <div class="selection-list">
                <div
                  class="list-item"
                  v-for="(item, index) in selection"
                  :key="index"
                >
                  <div class="item-left">
                    <div class="sortNumber">
                      {{ index + 1 }}
                    </div>
                    <div class="field-type">
                      {{ item.type }}
                    </div>
                  </div>
                  <div class="item-right">
                    <div class="flex-box">
                      <div class="field-name">
                        <span
                          v-if="item.verify"
                          style="color: red; margin-right: 2px"
                          >*</span
                        >
                        {{ item.label }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-card>
</template>

<script>
import * as corpUserApi from '@/api/corp/corpUser.js'
import { CheckOutlined } from '@ant-design/icons-vue'
export default {
  emits: ['done'],
  components: {
    CheckOutlined
  },
  props: {
    // 弹窗是否打开
    formId: String
  },
  computed: {
    selection() {
      return this.fieldList.filter((item) => {
        return item.isSelect === true
      })
    }
  },
  watch: {
    formId() {
      if (this.formId) {
        this.getTemField()
      }
    }
  },
  data() {
    return {
      // 表格搜索条件
      where: {},
      fieldList: []
    }
  },
  mounted() {
    if (this.formId) {
      this.getTemAllField()
    }
  },
  methods: {
    save() {
      const fieldArr = this.fieldList.filter((item) => {
        return item.isSelect === true
      })
      console.log('fieldArr', fieldArr)
      const fieldIdArr = fieldArr.map((item) => {
        return item.fieldId
      })
      const fields = this.arrToStr(fieldIdArr)
      corpUserApi
        .saveApplyField({ formId: this.formId, fields })
        .then((res) => {
          if (res.code === 0) {
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    enableField(item) {
      item.isSelect = !item.isSelect
    },
    reload() {
      this.getTemAllField()
    },
    reset() {
      this.where = {}
      this.getTemAllField()
    },
    getTemAllField() {
      corpUserApi
        .fieldList({
          ...this.where,
          // ...this.order,
          formId: this.formId,
          page: 1,
          limit: 1000
        })
        .then((res) => {
          if (res.code === 0) {
            this.fieldList = res.data.map((item) => {
              return {
                label: item.label,
                id: item.id,
                fieldId: item.fieldId,
                type: item.type,
                enable: item.enable,
                verify: item.verify,
                sortNumber: item.sortNumber,
                custom: item.custom,
                isSelect: false
              }
            })
            this.getTemField()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    getTemField() {
      corpUserApi
        .applyFieldList({
          ...this.where,
          // ...this.order,
          formId: this.formId,
          page: 1,
          limit: 1000
        })
        .then((res) => {
          if (res.code === 0) {
            if (res.data) {
              const resultArr = res.data.map((item) => {
                return {
                  label: item.label,
                  id: item.id,
                  fieldId: item.fieldId,
                  type: item.type,
                  enable: item.enable,
                  verify: item.verify,
                  sortNumber: item.sortNumber,
                  custom: item.custom,
                  isSelect: true
                }
              })
              resultArr.forEach((item) => {
                const index = this.fieldList.findIndex((val) => {
                  return item.fieldId === val.fieldId
                })
                this.fieldList.splice(index, 1, item)
              })
            }
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>
<style lang="less" scoped>
.add-field-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  .tips {
    margin-right: 10px;
  }
}
.selection-wrap {
  width: 100%;
  height: 500px;
  padding: 0 20px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: scroll;
}
.selection-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  &::after {
    content: '';
    display: inline-block;
    flex: 1;
  }
  .list-item:nth-child(3n) {
    margin-right: 0;
  }
  .list-item {
    height: 115px;
    width: 30%;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 20px;
    margin-right: calc(10% / 2);
    box-shadow: 0 0 10px 5px rgba(222, 225, 230, 0.7);
    .item-left {
      width: 20px;
      background-color: #e5e5e5;
      font-size: 15px;
      .sortNumber {
        width: 100%;
        height: 20px;
        text-align: center;
        line-height: 20px;
        background-color: #c4c4c4;
        color: #fff;
      }
      .field-type {
        // padding: 5px 0;
        width: 100%;
        vertical-align: middle;
        color: #969696;
        text-align: center;
      }
    }
    .item-right {
      flex: 1;
      background-color: #fff;
      display: flex;
      // align-items: center;
      flex-direction: column;
      justify-content: space-around;
      .field-name {
        font-size: 20px;
        font-weight: bold;
        flex: 1;
      }
      .required {
        display: flex;
        align-items: center;
        font-size: 16px;
      }
      .circle-bg-default {
        background-color: #fff;
      }
      .circle-bg-active {
        background-color: #3c9cff;
      }
      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        border-radius: 50%;
        height: 20px;
        margin-right: 10px;
        border: 1px solid #e4e4e4;
      }
    }
    .flex-box {
      display: flex;
      padding: 0px 20px;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid #e4e4e4;
      height: 50%;
      &:last-child {
        border: none;
      }
      .edit-btn {
        padding: 2px 10px;
        font-size: 16px;
        border-radius: 5px;
        margin-right: 20px;
        border: 1px solid #a4a4a4;
        color: #6b6b6b;
        cursor: pointer;
      }
    }
  }
}
.field-item-def {
  border: 1px solid #979797;
  color: #9b9b9b;
}
.field-item-act {
  border: 1px solid #3ba8cb;
  color: #3ba8cb;
}
.field-item {
  padding: 5px 0;
  text-align: center;
  position: relative;
  cursor: pointer;
  .xsj {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #00a8f3 transparent; /*上下颜色 左右颜色*/
    border-width: 0 0 25px 25px;
    border-style: solid;

    // background-color: ;
  }

  .icon {
    position: absolute;
    right: 0;
    bottom: -5px;
  }
}
.image {
  width: 120px;
  height: 90px;
}

.image :deep(.ant-image-img) {
  height: 100%;
}

:deep(.ud-tool) {
  display: none;
}
</style>
