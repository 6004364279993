<template>
  <div class="ud-body">
    <!-- <fromFieldList ref="corpApproveField" :formId="formId" @done="importDone" /> -->
   申请设置
  </div>
</template>

<script>
import fromFieldList from '@/views/frm/corp/corpApprove/field.vue'
export default {
  components: {
    // fromFieldList
  },
  data() {
    return {
      corpId: null,
      formId: null
    }
  },
  created() {
    this.corpId = this.getCorpId()
    this.formId = 'corp-' + this.corpId
  }
}
</script>

<style>
</style>